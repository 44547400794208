import { useRef, useState, useEffect } from 'react';

const THRESHOLD = 0;

export default function useInViewPort() {
  let nodeRef = useRef(null);
  let observerRef = useRef(null);

  const enable = typeof window !== 'undefined' && window.IntersectionObserver;
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (enable) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          setIsVisible(entries[0].isIntersecting);
        },
        { THRESHOLD }
      );

      observerRef.current.observe(nodeRef.current);

      return () => {
        observerRef.current.disconnect();
      };
    }
  }, [enable]);

  useEffect(() => {
    if (isVisible && observerRef) {
      observerRef.current.disconnect();
    }
  }, [isVisible]);

  return [nodeRef, isVisible];
}
