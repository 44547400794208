import './contact.scss';

import React, { useState } from 'react';
import { success as toastrSuccess, error as toastrError } from 'toastr';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import useForm from 'hooks/useForm';
import useInViewPort from 'hooks/useInViewPort';
import ReCAPTCHAWrapper from './recaptcha-wrapper.js';
import fetchHelper from 'helpers/fetchHelper';

import SectionTitle from 'components/SectionTitle/SectionTitle';
import FormInput from 'components/FormInput/FormInput';
import Switch from 'components/Switch/Switch';

import { ReactComponent as ContactIcon } from './contact-icon.svg';

const mainClassName = 'contact';

const isRequired = (value, errorMessage = 'Field is required') => (!value ? errorMessage : '');

const Contact = () => {
  const [captcha, setCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [nodeRef, isNodeVisible] = useInViewPort();

  const onSubmit = (values) => {
    return fetchHelper
      .post(`https://api.homeontour.com/v1/contactus/send`, {
        body: values,
      })
      .then((response) => {
        trackCustomEvent({
          category: 'Contact',
          label: 'Send Form',
          action: 'Sent',
        });
        toastrSuccess(`Thank you for getting in touch!`, 'Contact us', { timeOut: 5000 });
      })
      .catch((error) => {
        console.error(error);
        toastrError(
          `There was a problem with sending the form. Please try again later`,
          'Contact us',
          { timeOut: 5000 }
        );
      });
  };

  const { onChange, handleSubmit, submitting, values, errors } = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      newsletter: '',
    },
    onSubmit,
    options: {
      clearAfterSubmit: true,
    },
    validation: (values) => {
      const errors = {};

      errors.name = isRequired(values.name, 'Name is required');
      errors.email = isRequired(values.email, 'Email is required');
      errors.phone = isRequired(values.phone, 'Phone is required');
      errors.message = isRequired(values.message, 'Message is required');

      return errors;
    },
  });

  const reCaptchaWrapperSubmit = (event) => {
    if (!captcha) {
      event && event.preventDefault();
      setCaptchaError('reCAPTCHA is required. Please fill');
    } else {
      handleSubmit(event);
    }
  };

  return (
    <div className={mainClassName} ref={nodeRef}>
      <div
        className={`${mainClassName}__content`}
        data-aos="fade-bottom"
        data-aos-once={true}
        data-aos-duration={500}
        data-aos-offset={100}
      >
        <SectionTitle icon={<ContactIcon height="100%" />}>Contact us</SectionTitle>
        <div className={`${mainClassName}__contact-data`}>
          <a className={`${mainClassName}__contact-email`} href="mailto:contact@homeontour.com">
            contact@homeontour.com
          </a>
        </div>
        {isNodeVisible ? (
          <form
            className={`${mainClassName}__contact-fields-wrapper`}
            onSubmit={reCaptchaWrapperSubmit}
          >
            <div className={`${mainClassName}__contact-fields`}>
              <FormInput
                name="name"
                label="Name"
                value={values.name}
                onChange={onChange}
                autoComplete="name"
                error={errors.name}
                required
              />
              <FormInput
                name="email"
                label="Email"
                value={values.email}
                onChange={onChange}
                autoComplete="email"
                error={errors.email}
                required
              />
              <FormInput
                name="phone"
                label="Phone"
                value={values.phone}
                onChange={onChange}
                autoComplete="phone"
                error={errors.phone}
                required
              />
              <FormInput
                name="message"
                label="Message"
                value={values.message}
                onChange={onChange}
                autoComplete="message"
                error={errors.message}
                rows={3}
                required
                multipleLine
              />
              <Switch
                name="newsletter"
                value={values.newsletter}
                onChange={onChange}
                label="I agree to receive information and commercial offers from HomeOnTour"
                required
              />
              <div className={`${mainClassName}__recaptcha-wrapper`}>
                <ReCAPTCHAWrapper
                  onChange={(value) => {
                    setCaptcha(value);
                    setCaptchaError(null);
                  }}
                />
                {!!captchaError && (
                  <span className={`${mainClassName}__captcha-error`}>{captchaError}</span>
                )}
              </div>
              <div className={`${mainClassName}__button-wrapper`}>
                <button type="submit" disabled={submitting}>
                  {submitting ? 'SENDING...' : 'SEND'}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div style={{ height: 1000, minHeight: 1000 }} />
        )}
      </div>
    </div>
  );
};

export default Contact;
