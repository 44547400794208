import './section_title.scss';

import React from 'react';

const mainClassName = 'section-title';
const SectionTitle = ({ children, icon, iconHeight = 20 }) => {
  return (
    <div className={mainClassName}>
      <span className={`${mainClassName}__icon`} style={{ height: iconHeight }}>
        {icon}
      </span>
      <h2 className={`${mainClassName}__title`}>{children}</h2>
    </div>
  );
};

export default SectionTitle;
