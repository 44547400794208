const aboutData = {
  image: {
    src: require('./images/ipad-cover-photo.png'),
    srcWebp: require('./images/ipad-cover-photo.webp'),
    alt: 'Ipad Cover Photo',
  },
  title: `Real Estate Marketing Platform`,
  subtitle: `for Real Estate Photographers`,
  description: `We built an innovative software platform to serve your real estate photography business.
                                With automation at every step you can focus on what you do best and leave the rest to us.`,
  seeOutDemos: `See our demos`
};

export default aboutData;
