class HTTPError extends Error {}

const _fetch = async (
  method = "GET",
  url,
  { body, headers } = {}
) => {
  const response = await fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      ...headers,
    },
  });

  if (!response.ok) {
    throw new HTTPError(`Fetch error: ${response.statusText}`);
  }

  const parsed = await response.json();

  return parsed;
};

const fetchHelper = {
  get: (url, { body, headers, queryParams } = {}) =>
    _fetch("GET", url, { body, headers, queryParams }),
  post: (url, { body, headers, queryParams } = {}) =>
    _fetch("POST", url, { body, headers, queryParams }),
  put: (url, { body, headers, queryParams } = {}) =>
    _fetch("PUT", url, { body, headers, queryParams }),
  delete: (url, { headers, queryParams } = {}) =>
    _fetch("DELETE", url, { headers, queryParams }),
};

export default fetchHelper;