import './feature_card.scss';

import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

const mainClassName = 'feature-card';

const FeatureCard = ({ image, title, description, links, reverseImage, imageStyle, appImages }) => {
  return (
    <div className={mainClassName}>
      <div
        className={classnames('row', `${mainClassName}__row-items`, {
          [`${mainClassName}__row-items--reverse`]: reverseImage,
        })}
      >
        {!!image && (
          <div
            className={classnames(
              'col-xs-12',
              { 'col-md-6': !!image },
              `${mainClassName}__image-column`
            )}
          >
            <div className={`${mainClassName}__image`}>
              <picture>
                <source srcSet={image.srcWebp} type="image/webp" />
                <img
                  className={`${mainClassName}__img-feature`}
                  loading="lazy"
                  src={image.src}
                  alt={image.alt || ''}
                />
              </picture>
            </div>
          </div>
        )}
        <div
          className={classnames(
            'col-xs-12',
            { 'col-md-6': !!image },
            `${mainClassName}__content-column`
          )}
        >
          <div className={`${mainClassName}__content-wrapper`}>
            <div
              className={classnames(`${mainClassName}__content`, {
                [`${mainClassName}__content--with-image`]: !!image,
              })}
            >
              <div className={`${mainClassName}__title`}>{title}</div>
              <div className={`${mainClassName}__description`}>{description}</div>
              <div className={`${mainClassName}__links`}>
                {links && links.map(({label, url, isExternal}, index) => (
                  isExternal ? (
                    <a 
                      href={url} 
                      key={index} 
                      target="_blank"
                      className={classnames(`${mainClassName}__button-link`)}
                    >
                      {label}
                    </a>
                  ) : (
                    <Link
                      to={url}
                      key={index}
                      className={classnames(`${mainClassName}__button-link`)}
                    >
                      {label}
                    </Link>
                  )
                ))}
              </div>
              {appImages && (
                <div className={`${mainClassName}__app-images`}>
                  {appImages.map((appImage, index) => (
                    <img
                      className={`${mainClassName}__app-image`}
                      {...appImage}
                      key={index}
                      height={appImage.height || 20}
                      alt={appImage.alt || ''}
                      loading="lazy"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
