import React from 'react';
export class ReCAPTCHA extends React.Component {
  componentDidUpdate(prevProps) {
    console.log(this.props);
    // recaptcha has loaded via async script
    if (!prevProps.grecaptcha && this.props.grecaptcha) {
      this.props.grecaptcha.render(this._container, {
        sitekey: '6Ld2IFQUAAAAACHOwixVSHrWRDj_plsnPuXfwcnb',
        callback: this.props.onChange,
      });
    }
  }
  render() {
    return <div ref={(r) => (this._container = r)} />;
  }
}
