import './ipad.scss';

import React from 'react';
import TabletBox from 'components/TabletBox/TabletBox';
import aboutData from 'data/about/aboutData';

const mainClassName = 'ipad';

const Ipad = () => {
  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__content-wrapper`}>
        <div className={`${mainClassName}__content`}>
          <TabletBox
            content={
              <picture className={`${mainClassName}__tablet-content-image`}>
								<source srcSet={aboutData.image.srcWebp} type="image/webp" />
								<img src={aboutData.image.src} alt={aboutData.image.alt} loading="eager" />
							</picture>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Ipad;
