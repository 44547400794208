import React, { useEffect } from 'react';
import { animateScroll, Element as ScrolScrollElement } from 'react-scroll';

import getWindow from 'utils/getWindow';

import Layout from 'components/Layout/Layout';
import About from '../modules/About/About';
import Features from '../modules/Features/Features';
import Demos from 'modules/Demos/Demos';
import Packages from 'modules/Packages/Packages';
import Contact from 'modules/Contact/Contact';
import { navSectionId } from '../data/navigation/navSections';

const IndexPage = () => {
  const hash = getWindow()?.hash || '';

  useEffect(() => {
    const sectionId = hash.replace('#', '');

    if (sectionId && Object.values(navSectionId).indexOf(sectionId) >= 0) {
      animateScroll.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        containerId: 'container',
      });
    }
  }, [hash]);

  return (
    <Layout inMainPage >
      <div id="container">
        <ScrolScrollElement id="home"></ScrolScrollElement>
        <ScrolScrollElement name={navSectionId.about} id={navSectionId.about}>
          <About />
        </ScrolScrollElement>
        <ScrolScrollElement name={navSectionId.features} id={navSectionId.features}>
          <Features  />
        </ScrolScrollElement>
        <ScrolScrollElement name={navSectionId.demos} id={navSectionId.demos}>
          <Demos  />
        </ScrolScrollElement>

        <ScrolScrollElement name={navSectionId.packages} id={navSectionId.packages}>
          <Packages />
        </ScrolScrollElement>

        <ScrolScrollElement name={navSectionId.contact} id={navSectionId.contact}>
          <Contact />
        </ScrolScrollElement>
      </div>
    </Layout>
  );
};

export default IndexPage;
