/**
 *  Optimize JPG / PNG (https://tinypng.com/)
 */
const features = {
  mainFeatures: [
    {
      image: {
        src: require('./images/photo-hosting.jpg'),
        srcWebp: require('./images/photo-hosting.webp'),
        alt: 'Photo Hosting',
      },
      title: 'Photo Hosting',
      description:
        'Your beautiful photos will be uploaded in seconds and available as both a virtual tour and downloadable link that is instantly shareable with your agent.',
    },
    {
      image: {
        src: require('./images/video-hosting.png'),
        srcWebp: require('./images/video-hosting.webp'),
        alt: 'Video hosting',
      },
      title: 'Video Hosting',
      description:
        "We support YouTube, Vimeo, and Sprout. If we don't have your favourite provider we can add it.",
      appImages: [
        {
          src: require('./images/youtube.png'),
          alt: 'Youtube',
        },
        {
          src: require('./images/vimeo.png'),
          alt: 'Vimeo',
        },
        {
          src: require('./images/sproutvideo.png'),
          alt: 'Sprout Video',
        },
      ],
    },
    {
      title: '3D Tours',
      image: {
        src: require('./images/3d-tour.png'),
        srcWebp: require('./images/3d-tour.webp'),
        alt: '3D tour',
      },
      description:
        'We support Matterport, IGuide, Ricoh360, Cloudpano, Zillow, and GeoCV. Our staff can easily add any new vendors.',
      appImages: [
        {
          src: require('./images/matterport.jpg'),
          alt: 'Matterport',
        },
        {
          src: require('./images/iGUIDE.png'),
          alt: 'iGUIDE',
        },
        {
          src: require('./images/cloudpano.png'),
          alt: 'Cloud pano',
        },
        {
          src: require('./images/ricoh360-tours.png'),
          alt: 'RICOH360 Tours',
        },
        {
          src: require('./images/Zillow-3d-Home-View.jpg'),
          alt: 'Zillow 3d Home view',
          style: {
            height: '35px',
            margin: '5px 15px 15px 15px',
          },
        },
      ],
    },
    {
      image: {
        src: require('./images/mobile-optimized-design.png'),
        srcWebp: require('./images/mobile-optimized-design.webp'),
        alt: 'Mobile Optimized Design',
      },
      imageStyle: {
        maxWidth: 350,
      },
      title: 'Mobile Optimized Design',
      description:
        'With over half of our viewers using mobile devices we spend extra time ensuring our website stays beautiful across all devices.',
    },
    {
      image: {
        src: require('./images/dedicated-support.png'),
        srcWebp: require('./images/dedicated-support.webp'),
        alt: 'Dedicated Support'
      },
      imageStyle: {
        maxWidth: 350,
      },
      title: 'Dedicated Support',
      description:
        'Our team is dedicated to supporting our customers. For every client we provide in-person onboarding session as well as email and phone support at no additional cost. We routinely implement our clients\' ideas so we can better serve your unique needs.',
    },
  ],
  restFeatures: [
    {
      title: 'Unlimited everything',
      description:
        'We don\'t limit the amount of storage, number of agents, team members, or the time your tour stays online. All our clients get to enjoy a truly unlimited services with no hidden fees.',
    },
    {
      title: 'Content Delivery',
      description:
        'All media is delivered in one simple link to the Media Center with property photos ready to download in a number of customizable formats.',
        links: [
          {
            label: 'Example', 
            url: 'https://media.homeontour.com/ca1e7aa44c3d0084aadfb5fd17b6acba',
            isExternal: true
          }
        ]
    },
    {
      title: 'Single Property Websites',
      description:
        'The core of our platform is a beautifully made website that your agents use to advertise their listings. All our websites are mobile ready and provide the same user experience across all platforms.'
    },
    {
      title: 'Multiple Templates',
      description:
        'We offer multiple templates each with a unique functionality including immersive photo slideshow, animations, and Vimeo video playback to name a few.',
    },
    {
      title: 'Storefront & Orders',
      description:
        'Embed a customizable order form on your business website and integrate with your appointment calendar, payments, invoicing, and others.',
      links: [
        //{label: 'Example 1', url: '/order-1'},
        //{label: 'Example 2', url: '/order-2'}
      ]
    },
    {
      title: 'Video Slideshow',
      description:
        'For each tour we can generate an immersive video that your agents can download and use on their Instagram and Facebook pages.',
    },
   
    //{
    //  title: 'Order Management',
    //  description:
    //    'Would you like to receive agent orders straight from your website? We have got that imageed as well.',
    //},
    {
      title: 'Agent Portal',
      description:
        'Each of your customers has access to an admin portal where they can edit their tours saving you time and hassle.',
    },
    {
      title: 'Multi-Photographer Support',
      description:
        'Have a team of photographers? No problem, we provide functionality to manage workflows across multiple team members.',
    },
    {
      title: 'Payments Support',
      description:
        'We are fully integrated with Stripe and Clover so you can charge your customers directly from our platform. Using QuickBooks or PayPal for your payments? That\'s not a problem either.',
    },
    {
      title: 'White-labelling',
      description:
        'We can use your domain, logo and email address to ensure that your brand is always first.',
    },
    {
      title: 'Custom Domains',
      description:
        'Your agents require a custom domain for their listing? You can easily do that with our platform. We don\'t force you to buy a domain from us, so you will save money as well.',
    },
    {
      title: 'Music',
      description:
        'Our service comes with a pre-built library of songs that you are free to use. If you have your own, no problem, we can add it as well.',
    },
    {
      title: 'MLS Compliance',
      description:
        'All our tours are complaint with the majority of real estate boards across North America. If your board has special requirements, we will add them for free.',
    },
    {
      title: 'Traffic Reports',
      description:
        'Each tour comes with a traffic report that can be shared with your clients and is updated in real-time.',
    },
    /*{
      title: 'Client Portfolios',
      description:
        'With the client portfolios websites option, you can have all your listings in one place.',
    },
    {
        title: 'API\'s',
        description:
         'Are you working on an advanced use cases. All our functionality is available through a set of API\'s ready to be connected to your service',
    },*/
    {
        title: 'Moving Package',
        description:
         'If you are a customer of one of the other platforms we now offer 3 months for FREE while you migrate. We will also provide dedicated support and upload your customer information in bulk.',
    },
    //{
    //  title: 'Brochure',
    //  description:
    //    'For each tour we also automatically generate a set of beautiful brochures that are ready to print for your agent',
    //}
  ],
};

export default features;
