import makeAsyncScriptLoader from 'react-async-script';
import { ReCAPTCHA } from './recaptcha';

const callbackName = 'onloadcallback';
const URL = `https://www.google.com/recaptcha/api.js?onload=${callbackName}&render=explicit`;
// the name of the global that recaptcha/api.js sets on window ie: window.grecaptcha
const globalName = 'grecaptcha';

export default makeAsyncScriptLoader(URL, {
  callbackName: callbackName,
  globalName: globalName,
})(ReCAPTCHA);
