import './demos.scss';

import React from 'react';
import classnames from 'classnames';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import SectionTitle from 'components/SectionTitle/SectionTitle';
import Slider from 'components/Slider/Slider';
import demosData from 'data/demos/demosData';

import { ReactComponent as DemosLogo } from './demos-icon.svg';
import Demo from './Demo';

const mainClassName = 'demos';
const Demos = () => {
  const handleDemoClicked = (name) => {
    trackCustomEvent({
      category: 'Demo',
      label: `Demo ${name}`,
      action: 'Clicked',
    });
  };

  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__content-wrapper`}>
        <div className={`${mainClassName}__content`}>
          <div className={`${mainClassName}__section-tittle-wrapper`}>
            <SectionTitle iconHeight={35} icon={<DemosLogo height="100%" />}>
              {demosData.title}
            </SectionTitle>
          </div>
          <div className={classnames(`${mainClassName}__demos-grid`, `row`)}>
            {demosData.items.map((demo, index) => (
              <div
                key={index}
                className={classnames(`col-lg-4`, `${mainClassName}__demo-wrapper`)}
                data-aos={!!(Math.floor(index / 3) % 2) ? 'fade-left' : 'fade-right'}
                data-aos-duration={500}
                data-aos-offset={100}
                data-aos-once={true}
              >
                <Demo
                  {...demo}
                  viewTourLabel={demosData.viewTourButton}
                  onClicked={handleDemoClicked}
                />
              </div>
            ))}
          </div>
          <div className={classnames(`${mainClassName}__demos-slider`)}>
            <Slider autoPlaySpeed={demosData.autoPlaySpeed}>
              {demosData.items.map((demo, index) => (
                <Demo
                  key={index}
                  viewTourLabel={demosData.viewTourButton}
                  {...demo}
                  onClicked={handleDemoClicked}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demos;
