import './switch.scss';
import React from 'react';

const mainClassName = 'switch';
const Switch = ({ name, value, onChange, label, required }) => {
  return (
    <div className={mainClassName}>
      <input
        name={name}
        className={`${mainClassName}__checkbox`}
        id={name}
        type="checkbox"
        checked={value}
        onChange={onChange}
        // required={required}
      />
      <label className={`${mainClassName}__label`} htmlFor={name}>
        <span className={`${mainClassName}__button-wrapper`}>
          <span className={`${mainClassName}__button`} />
        </span>
        {label && <span className={`${mainClassName}__label-content`}>{label}</span>}
      </label>
    </div>
  );
};

export default Switch;
