import './demo.scss';

import React from 'react';

const mainClassName = 'demo';
const Demo = ({
  name,
  address,
  image,
  webpImage,
  webpImageSmall,
  imageSmall,
  href,
  viewTourLabel = 'VIEW TOUR',
  onClicked
}) => {
  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__content`}>
        <a
          href={href}
          className={`${mainClassName}__background-wrapper`}
          target="_blank"
          rel="noreferrer"
          onClick={() => onClicked(name)}
        >
          <picture className={`${mainClassName}__background`}>
            <source
              srcSet={`${webpImage} 800w, ${webpImageSmall} 350w`}
              sizes={`(max-width: 400px) 350px, (max-width: 991) 800px, 350px`}
              type="image/webp"
            />
            <img
              srcSet={`${image} 800w, ${imageSmall} 350w`}
              sizes={`(max-width: 400px) 350px, (max-width: 991) 800px, 350px`}
              alt={name}
              loading="lazy"
            />
          </picture>
          <button className={`${mainClassName}__view-tour-button-mobile`}>{viewTourLabel}</button>
        </a>
        <div className={`${mainClassName}__hover-wrapper`}>
          <div className={`${mainClassName}__description`}>
            <div className={`${mainClassName}__name`}>{name}</div>
            {!!address || (true && <div className={`${mainClassName}__address`}>{address}</div>)}
            <button className={`${mainClassName}__view-tour-button`}>{viewTourLabel}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
