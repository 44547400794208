import './form_input.scss';

import React from 'react';

const FormInput = ({
  label,
  value,
  onChange,
  name,
  autoComplete = 'on',
  type = 'text',
  pattern = undefined,
  required = false,
  rows = undefined,
  maxLength = undefined,
  multipleLine = false,
  error = '',
}) => {
  const commonInputProps = {
    type,
    id: name,
    name,
    autoComplete,
    value,
    onChange,
    // required,
    pattern,
    maxLength,
    error,
  };

  return (
    <div className={`form-input ${!!value ? 'fill' : ''}`}>
      <label htmlFor={name}>
        {label}
        {!!required && '*'}
      </label>
      {multipleLine ? (
        <textarea rows={rows} {...commonInputProps} />
      ) : (
        <input {...commonInputProps} />
      )}

      {<span className="form-input__error">{error}</span>}
    </div>
  );
};

export default FormInput;
