import './about.scss';

import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import aboutData from 'data/about/aboutData';
import arrowTop from './arrow-top.png';
import { navSectionId } from '../../data/navigation/navSections';
import GeometryAnimation from './components/GeometryAnimation/GeometryAnimation';
// import IpadSlider from './components/IpadSlider/IpadSlider';
import Ipad from './components/Ipad/Ipad';

const mainClassName = 'about-section';
const About = () => {
  return (
    <>
      <section className={mainClassName}>
        <GeometryAnimation />
        <div className={`${mainClassName}__content`}>
          <div className="row">
            <div
              className="col-xs-12 col-lg-6"
              data-aos="fade-right"
              data-aos-duration={500}
              data-aos-once={true}
            >
              <div className={`${mainClassName}__title-wrapper`}>
                <div className={`${mainClassName}__title`}>
                  <div className={`${mainClassName}__arrow-top-wrapper`}>
                    <img
                      loading="eager"
                      fadeIn="false"
                      src={arrowTop}
                      className={`${mainClassName}__arrow-top`}
                      alt="arrow top"
                      height="25px"
                      width="auto"
                    />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: aboutData.title }} />
                </div>
              </div>
              <div className={`${mainClassName}__subtitle`}>{aboutData.subtitle}</div>
            </div>
            <div
              className="col-xs-12 col-lg-6"
              data-aos="fade-left"
              data-aos-duration={500}
              data-aos-once={true}
            >
              <div className={`${mainClassName}__description-wrapper`}>
                <div className={`${mainClassName}__description`}>{aboutData.description}</div>
                <div>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    to={navSectionId.demos}
                    className={`${mainClassName}__see-our-demos-button`}
                  >
                    {aboutData.seeOutDemos}
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Ipad />
      {/* <IpadSlider /> */}
    </>
  );
};

export default About;
