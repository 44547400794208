import './subscription_package.scss';

import React from 'react';

import { ReactComponent as CheckIcon } from './check.svg';

const mainClassName = 'subscription-package';

const SubscriptionPackage = ({
  name,
  description,
  price = 0,
  details,
  url,
  advantages = [],
  isBest = false,
  icon,
  onClicked
}) => {
  return (
    <div className={mainClassName}>
      <span className={`${mainClassName}__header-bookmark`} />

      {!!isBest && <span className={`${mainClassName}__our-best`}>MOST COMMON</span>}
      <div className={`${mainClassName}__content`}>
        <div>
          <div className={`${mainClassName}__header`}>
            <div className={`${mainClassName}__name`}>
              <div>{name}</div>
              {icon && (
                <img
                  className={`${mainClassName}__name-icon`}
                  src={icon}
                  alt={`${name} package icon`}
                />
              )}
            </div>
            {!!description && <div className={`${mainClassName}__description`}>{description}</div>}
          </div>
          <hr className={`${mainClassName}__header-break-line`} />
          <div className={`${mainClassName}__payment-row`}>
            <div className={`${mainClassName}__price-wrapper`}>
              <span className={`${mainClassName}__price-value`}>
                {details ? details : price > 0 ? `$${price}` : `Free`}
              </span>
              {price > 0 && (
                <span className={`${mainClassName}__payment-frequency`}>per month</span>
              )}
            </div>
            {!!url && (
              <div className={`${mainClassName}__mobile-subscribe-button-wrapper`}>
                <a 
                  className={`${mainClassName}__mobile-subscribe-button`} 
                  href={url} 
                  target="_blank" 
                  rel="noreferrer"
                  onClick={() => onClicked(price)}
                >
                  SUBSCRIBE
                </a>
              </div>
            )}
          </div>
          <ul className={`${mainClassName}__advantages`}>
            {advantages.map((advantage) => (
              <li className={`${mainClassName}__advantage-item`} key={advantage}>
                <CheckIcon className={`${mainClassName}__advantage-check`} width={15} />
                <span className={`${mainClassName}__advantage-content`}>{advantage}</span>
              </li>
            ))}
          </ul>
        </div>

        {!!url && (
          <div className={`${mainClassName}__subscribe-button-wrapper`}>
            <a 
              className={`${mainClassName}__subscribe-button`} 
              href={url} 
              target="_blank" 
              rel="noreferrer"
              onClick={() => onClicked(price)}
            >
              SUBSCRIBE
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPackage;
