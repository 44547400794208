import './features.scss';

import React from 'react';
import classnames from 'classnames';

import SectionTitle from 'components/SectionTitle/SectionTitle';
import FeatureCard from './FeatureCard';
import { ReactComponent as FeaturesIcon } from './features-icon.svg';
import features from 'data/features/featuresData';

const mainClassName = 'features';
const Features = () => {
  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__content`}>
        <SectionTitle icon={<FeaturesIcon height="100%" />}>Features</SectionTitle>
        <div className="row">
          {features.mainFeatures.map((feature, index) => {
            return (
              <div
                key={index}
                className={classnames(`${mainClassName}__feature-card`, 'col-xs-12')}
                data-aos={!!(index % 2) ? 'fade-left' : 'fade-right'}
                data-aos-duration={500}
                data-aos-offset={100}
                data-aos-once={true}
              >
                <FeatureCard
                  reverseImage={!!(index % 2)}
                  image={feature.image}
                  imageStyle={feature.imageStyle}
                  title={feature.title}
                  description={feature.description}
                  appImages={feature.appImages}
                />
              </div>
            );
          })}
          {features.restFeatures.map((feature, index) => {
            return (
              <div
                key={index}
                className={classnames(
                  `${mainClassName}__rest-feature-card`,
                  'col-xs-12',
                  'col-md-6',
                  'col-lg-4'
                )}
                data-aos="fade-bottom"
                data-aos-once={true}
              >
                <FeatureCard title={feature.title} description={feature.description} links={feature.links} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
