import './tablet_box.scss';

import React from 'react';

import ipad from './ipad.png';

const mainClassName = 'tablet-box';

const TabletBox = ({ content }) => {
  return (
    <div className={mainClassName} data-aos="fade-top" data-aos-once={true} data-aos-offset={100}>
      <div className={`${mainClassName}__wrapper`}>
        <img loading="eager" fadeIn="false" src={ipad} alt="ipad-border" className={`${mainClassName}__tablet-border`} />
        <div className={`${mainClassName}__content`}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default TabletBox;
