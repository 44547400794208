import './geometry_animation.scss';

import React from 'react';

const mainClassName = 'geometry-animation';

const GeometryAnimation = () => {
  return (
    <div className={mainClassName}>
      {Array.apply(null, Array(7)).map((value, index) => (
        <li key={index}/>
      ))}
    </div>
  );
};

export default GeometryAnimation;
