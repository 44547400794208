/*
  Images 800x600 (4:3)
  Optimize JPG / PNG (https://tinypng.com/)
*/

/**
 * autoPlaySpeed - timeout to slide to next item, in miliseconds. 0 - disable
 * Images 800x600 (4:3)
 * Optimize JPG / PNG (https://tinypng.com/)
 */
const demosData = {
  title: `Our demos`,
  autoPlaySpeed: 5000,
  viewTourButton: 'VIEW TOUR',
  items: [
    {
      name: 'California',
      address: '',
      href: 'https://tour.homeontour.com/cOJQasUoK?branded=1',
      image: require('./images/california.jpg'),
      webpImage: require('./images/california.webp'),
      imageSmall: require('./images/california_350.jpg'),
      webpImageSmall: require('./images/california_350.webp'),
    },
    {
      name: 'Apartment',
      address: '',
      href: 'https://tour.homeontour.com/3905-311-bay-st-toronto-on-m5h-4g5-XhUOIY5zz?branded=1',
      image: require('./images/apartment.jpg'),
      webpImage: require('./images/apartment.webp'),
      imageSmall: require('./images/apartment_350.jpg'),
      webpImageSmall: require('./images/apartment_350.webp'),
    },
    {
      name: 'Suburban',
      address: '',
      href: 'https://tour.homeontour.com/xWwZqlEti?branded=1',
      image: require('./images/suburban.jpg'),
      webpImage: require('./images/suburban.webp'),
      imageSmall: require('./images/suburban_350.jpg'),
      webpImageSmall: require('./images/suburban_350.webp'),
    },
    {
      name: 'Heritage',
      address: '',
      href: 'https://tour.homeontour.com/111-hazelton-ave-toronto-on-m5r-2e4-wFTHUwC8i?branded=1',
      image: require('./images/heritage.jpg'),
      webpImage: require('./images/heritage.webp'),
      imageSmall: require('./images/heritage_350.jpg'),
      webpImageSmall: require('./images/heritage_350.webp'),
    },
    {
      name: 'Mansion',
      address: '',
      href: 'https://tour.homeontour.com/9zYRfg5ko?branded=1',
      image: require('./images/mansion.jpg'),
      webpImage: require('./images/mansion.webp'),
      imageSmall: require('./images/mansion_350.jpg'),
      webpImageSmall: require('./images/mansion_350.webp'),
    },
    {
      name: 'Upscale',
      address: '',
      href: 'https://tour.homeontour.com/estates-drive-villa-park-ca-92861?branded=1',
      image: require('./images/upscale.jpg'),
      webpImage: require('./images/upscale.webp'),
      imageSmall: require('./images/upscale.jpg'),
      webpImageSmall: require('./images/upscale.webp'),
    }
  ],
};

export default demosData;
