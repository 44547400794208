const subscriptionPackagesData = [
  {
    name: `Free`,
    description: `Let's grow your business together`,
    price: 0,
    advantages: [`Up to 5 active tours`],
    url: 'https://admin.homeontour.com/sign-up?package=packagePhotographerFree&promoId=19c3b9194ad4ad56272ce9a8c8853bba57897075',
    // icon: require('./young-adept-icon.png'),
  },
  {
    name: `Side Hustle`,
    description: `Photography is your side job`,
    price: 39,
    advantages: [`120 new tours annually`, `10 new tours per month`,`Cancel any time`],
    url: 'https://admin.homeontour.com/sign-up?package=package39',
    //icon: require('./side-hustle-icon.png'),
  },
  {
    name: `Local Legend`,
    description: `Full time photographer`,
    price: 79,
    advantages: [`360 new tours annually`, `30 new tours per month`,`Cancel any time`],
    isBest: true,
    url: `https://admin.homeontour.com/sign-up?package=package79`,
  },
  {
    name: 'Agency',
    description: `Boutique Agency`,
    price: 139,
    advantages: [`720 new tours annually`, `60 new tours per month`, `$2.50 per tour above 60`,`Cancel any time`],
    url: `https://admin.homeontour.com/sign-up?package=package139`,
  },
  {
    name: 'Enterprise',
    description: `Pay Per Tour`,
    price: 0,
    details: 'Contact Us',
    advantages: [`Charged at the end of the month`, `Cancel any time`],
    url: `https://admin.homeontour.com/sign-up&promoId=19c3b9194ad4ad56272ce9a8c8853bba57897075`,
  },
];

export default subscriptionPackagesData;
