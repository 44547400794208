import './packages.scss';

import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import breakPoints from 'styles/breakpoints';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import Slider from 'components/Slider/Slider';

import subscriptionPackagesData from 'data/subscriptionPackages/subscriptionPackagesData';

import { ReactComponent as PackagesIcon } from './pick-your-packages-logo.svg';

import SubscriptionPackage from './SubscriptionPackage';

const mainClassName = 'packages';

const Packages = () => {
  const handleSubscriptionClicked = (price) => {
    trackCustomEvent({
      category: 'Package',
      label: `Package ${price}`,
      action: 'Clicked',
    });
  };

  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__content-wrapper`}>
        <div
          className={`${mainClassName}__content`}
          data-aos="fade-bottom"
          data-aos-once={true}
          data-aos-duration={500}
          data-aos-offset={100}
        >
          <SectionTitle icon={<PackagesIcon height="100%" />}>Pick your package</SectionTitle>
          <Slider
            itemPadding={[0, 15, 0, 15]}
            breakPoints={[
              {
                width: 1,
                itemsToShow: 1,
              },
              {
                width: breakPoints.sm,
                itemsToShow: 2,
                itemsToScroll: 1,
              },
              {
                width: breakPoints.md,
                itemsToShow: 3,
                itemsToScroll: 1,
              },
              {
                width: breakPoints.lg,
                itemsToShow: 4,
                itemsToScroll: 1,
              },
            ]}
          >
            {subscriptionPackagesData.map((subscriptionPackage, index) => (
              <SubscriptionPackage
                key={index}
                {...subscriptionPackage}
                onClicked={handleSubscriptionClicked}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Packages;
