import './slider.scss';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as AngleRightSolid } from './right-arrow.svg';
import { ReactComponent as AngleLeftSolid } from './left-arrow.svg';

import Carousel, { consts } from 'react-elastic-carousel';

const mainClassName = 'slider';

const propTypes = {
  breakPoints: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      itemsToShow: PropTypes.number,
      itemsToScroll: PropTypes.number,
      pagination: PropTypes.bool,
    })
  ),
  itemPadding: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  breakPoints: [],
  itemPadding: [],
};

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
const Slider = ({ children, breakPoints, itemPadding, autoPlaySpeed = 0, hideArrows = false }) => {
  const [pageIndex, onChangePageIndex] = useState(0);
  const [disableSwipe, setDisableSwipe] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (autoPlaySpeed && ref.current && ref.current.state.pages.length - 1 === pageIndex) {
      const timeout = setTimeout(() => {
        ref.current.goTo && ref.current.goTo(0);
      }, autoPlaySpeed);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoPlaySpeed, pageIndex, ref]);

  return (
    <Carousel
      ref={ref}
      onResize={({ width }) => {
        if (
          breakPoints.find((breakPoint) => breakPoint.width >= width)?.itemsToShow ===
          children?.length
        ) {
          !disableSwipe && setDisableSwipe(true);
        } else {
          disableSwipe && setDisableSwipe(false);
        }
      }}
      enableSwipe={!disableSwipe}
      enableMouseSwipe={false}
      showArrows={!disableSwipe}
      autoPlaySpeed={autoPlaySpeed}
      enableAutoPlay={!disableSwipe && autoPlaySpeed > 0}
      itemPadding={itemPadding}
      className={classnames(mainClassName, { [`${mainClassName}--hidden-arrows`]: hideArrows || disableSwipe })}
      breakPoints={breakPoints}
      onChange={(page, pageIndex) => onChangePageIndex(pageIndex)}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <ul
            className={classnames(`${mainClassName}__pagination`, {
              [`${mainClassName}__pagination--hidden`]: pages.length <= 1,
            })}
          >
            {pages.map((page) => (
              <li
                key={page}
                className={classnames(`${mainClassName}__pagination-page`, {
                  [`${mainClassName}__pagination-page--selected`]: activePage === page,
                })}
                onClick={onClick.bind(null, page)}
              />
            ))}
          </ul>
        );
      }}
      renderArrow={(props) => {
        const { type, onClick, isEdge } = props || {};
        if (type === consts.NEXT) {
          return (
            <div
              className={classnames(`${mainClassName}__next-button-wrapper`, {
                [`${mainClassName}__next-button-wrapper--hidden`]: hideArrows,
              })}
            >
              <button
                className={`${mainClassName}__next-button`}
                aria-label="Go to next slide"
                type="button"
                onClick={onClick}
                disabled={isEdge}
              >
                <div className={`${mainClassName}__next-button-icon-wrapper`}>
                  <AngleRightSolid height={20} />
                </div>
              </button>
            </div>
          );
        } else {
          return (
            <div
              className={classnames(`${mainClassName}__prev-button-wrapper`, {
                [`${mainClassName}__prev-button-wrapper--hidden`]: hideArrows,
              })}
            >
              <button
                className={`${mainClassName}__prev-button`}
                aria-label="Go to prev slide"
                type="button"
                onClick={onClick}
                disabled={isEdge}
              >
                <div className={`${mainClassName}__prev-button-icon-wrapper`}>
                  <AngleLeftSolid height={20} />
                </div>
              </button>
            </div>
          );
        }
      }}
    >
      {children}
    </Carousel>
  );
};

/* eslint-enable  jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;
