import { useState } from "react"

const useForm = ({
  initialValues = {},
  onSubmit,
  validation,
  options,
  onSubmitValidationFailure,
}) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [submittingFailure, setSubmittingFailure] = useState(false)
  const [touched, setTouched] = useState({})

  const onBlur = event => {
    const { target } = event
    const { name } = target
    setTouched({ ...touched, [name]: true })
    setErrors(validation ? validation(values) : {})
  }

  const onChange = event => {
    const { name, value, checked, type } = event.target

    const newValues = { ...values, [name]: type ==='checkbox' ? checked : value }
    setValues(newValues)
    setErrors(validation ? validation(newValues) : {})
    setSubmittingFailure(false);
  }

  const handleSubmit = event => {
    event && event.preventDefault()

    const newErrors = validation ? validation(values) : {}
    const newTouched = { ...touched }
    Object.keys(newErrors).forEach(key => (newTouched[key] = true))

    const isSomeErrors =
      Object.values(newErrors).filter(errorValue => !!errorValue).length > 0

    if (isSomeErrors) {
      setErrors(newErrors)
      setTouched(newTouched)

      onSubmitValidationFailure &&      onSubmitValidationFailure(newErrors)
    } else {
      const submit = onSubmit(values)
      if (submit.then) {
        setSubmitting(true)
        setSubmittingFailure(false)
        submit
          .then(() => {
            setSubmitting(false)
            if (options.clearAfterSubmit) {
              setValues(initialValues)
              setErrors({})
              setTouched({})
            }
          })
          .catch(error => {
            console.error(error)
            setSubmittingFailure(true)
            setSubmitting(false)
          })
      }
    }
  }

  const getOnlyTouchedErrors = () => {
    const touchedErrors = {}
    Object.keys(touched)
      .filter(key => touched[key])
      .forEach(key => {
        touchedErrors[key] = errors[key]
      })

    return touchedErrors
  }

  return {
    onChange,
    onBlur,
    handleSubmit,
    submitting,
    submittingFailure,
    values,
    errors: getOnlyTouchedErrors(),
  }
}

export default useForm
